<template>
  <div>
    <AppBar />
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <h2>出荷実績抽出</h2>
    </v-container>
    <v-container id="main-area">
      <v-row>
        <v-col sm="8">
          <v-row>
            <v-col sm="2">
              <div class="label">搬入日付From<span class="require">*</span></div>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="txt-delivery-date-from"
                    v-model="deliveryDateCalFrom"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :single-line="true"
                    :rules="[rules.from_to(deliveryDateCalFrom, deliveryDateCalTo)]"
                    class="txt-single"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deliveryDateFrom"
                  @input="dateMenuFrom = false"
                ></v-date-picker> </v-menu
            ></v-col>
            <v-col sm="1"> <div style="text-align: center; line-height: 3rem">～</div></v-col>
            <v-col sm="2">
              <div class="label">搬入日付To<span class="require">*</span></div>
            </v-col>
            <v-col sm="3">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="txt-delivery-date-to"
                    v-model="deliveryDateCalTo"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :single-line="true"
                    :rules="[rules.from_to(deliveryDateCalFrom, deliveryDateCalTo)]"
                    class="txt-single"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deliveryDateTo"
                  @input="dateMenuTo = false"
                ></v-date-picker> </v-menu
            ></v-col>
          </v-row>
        </v-col>
        <v-col sm="4">
          <v-row>
            <v-col sm="4">
              <div class="label">営業所<span class="require">*</span></div>
            </v-col>
            <v-col sm="8">
              <v-select
                id="lst-sales-office"
                class="list-single"
                v-model="salesOfficeSelected"
                :items="salesOfficeList"
                :single-line="true"
            /></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <v-row>
            <v-col sm="4">
              <div class="label">製作所</div>
            </v-col>
            <v-col sm="8">
              <v-select
                id="lst-factory"
                class="list-multi"
                v-model="mfgSelected"
                :items="mfgList"
                multiple
            /></v-col>
          </v-row>
        </v-col>
        <v-col sm="4">
          <v-row>
            <v-col sm="3">
              <div class="label">取引先</div>
            </v-col>
            <v-col sm="9">
              <v-select
                id="lst-suppliers"
                class="list-multi"
                v-model="supplierSelected"
                :items="supplierList"
                multiple
            /></v-col>
          </v-row>
        </v-col>
        <v-col sm="4">
          <v-row>
            <v-col sm="4">
              <div class="label">搬入場所</div>
            </v-col>
            <v-col sm="8">
              <v-text-field
                id="txt-delivery-dest-code"
                class="txt-single"
                v-model="deliveryDest"
                maxlength="10"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-card id="download-area">
        <v-btn id="btn-file-output" @click="fileOutput">ファイル出力</v-btn>
        <v-btn id="btn-clear" @click="btnClear">クリア</v-btn>
        <div id="download-link-area" v-show="isUrlLink">
          <span id="download-link-label">ファイルダウンロードリンク：</span>
          <span id="link-file-download">
            <a :href="downloadUri">{{ downloadNm }}</a>
          </span>
        </div>
      </v-card>
    </v-container>
    <!--OKダイアログ-->
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
    />
  </div>
</template>

<script>
import AppBar from "../components/AppBar.vue";
import Loading from "@/components/loading";
import { appConfig } from "../assets/scripts/js/AppConfig";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
import { screenAuth } from "../assets/scripts/js/ScreenAuth";

export default {
  name: "progress_detail",
  components: {
    AppBar,
    Loading,
    SimpleDialog,
  },
  data: () => ({
    loadingCounter: 0,
    dateMenuTo: false,
    deliveryDateTo: null,
    dateMenuFrom: false,
    deliveryDateFrom: null,
    salesOfficeSelected: "",
    salesOfficeList: [],
    // salesOfficeList: [{ text: "狭山営業所", value: "testGroup" }],
    mfgSelected: [],
    mfgList: [],
    supplierSelected: [],
    supplierList: [],
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    deliveryDest: "",
    downloadUri:
      "http://s3.xxxx.yyyy/エンコードした「出荷実績抽出結果」_システム日時（yyyyMMddhhmm）_userId.csv",
    downloadNm: "",
    isUrlLink: false,
    rules: {
      from_to: (from, to) => {
        return new Date(from) <= new Date(to) || messsageUtil.getMessage("P-MNG-003_004_E");
      },
    },
  }),
  methods: {
    /**
     * 初期化処理
     */
    init() {
      //this.$httpClient.getApiGatewayCredentials2();
      console.log("初期化処理-開始-");
      screenAuth.canAccess();
      // MEMO クレデンシャルのスレッドセーフな取得が出来ないため、
      //      先にクレデンシャルを更新します。
      //this.$httpClient
      //  .getApiGatewayCredentials()
      ///  .then((result) => {
      //    console.info(result);
      const salesOfficeProcess = this.getSalesOfficeList();
      const mfgProcess = this.getMfgList();
      const supplierProcess = this.getSupplierList();

      let date = new Date();
      // 搬入日付Toに当日を設定
      this.deliveryDateTo = this.dateCalc(date);
      // 搬入日付Fromに前日を設定
      date.setDate(date.getDate() - 1);
      this.deliveryDateFrom = this.dateCalc(date);
      const salesOfficeCode = sessionStorage.getItem("sales_office_code");
      this.salesOfficeSelected = salesOfficeCode;

      Promise.all([salesOfficeProcess, mfgProcess, supplierProcess])
        .then((result) => {
          // 画面の初期値を設定します。

          this.salesOfficeList = result[0];
          const compareResult = this.salesOfficeList.some((u) => u.value === salesOfficeCode);
          if (!compareResult) {
            this.salesOfficeSelected = this.salesOfficeList[0].value;
          }
          this.mfgList = result[1];
          this.supplierList = result[2];
        })
        .catch((ex) => {
          console.error("init error", ex);
        });
      //  })
      //  .catch();
    },
    /**
     * 拠点マスタ参照API（営業所）を呼び出して返します。（拠点区分：日梱）
     */
    getSalesOfficeList() {
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      // 条件：システム運用会社（日梱拠点）
      config.params.baseType = appConfig.BASE_TYPE.systemOperationCompany;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.bases, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.resBases.forEach((row) => {
                list.push({ text: row.baseNameAlt, value: row.baseCode });
              });
              this.salesOfficeList = list;
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(list);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
          });
      });
    },
    /**
     * 拠点マスタ参照API（製作所）を呼び出して返します。（拠点区分：製作所）
     */
    getMfgList() {
      screenAuth.canAccess();
      console.log("getDeliveryPlaceList--start--");
      const config = this.$httpClient.createGetApiRequestConfig();
      // 条件：システム運用会社（日梱拠点）
      config.params.baseType = appConfig.BASE_TYPE.factory;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.bases, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.resBases.forEach((row) => {
                list.push({ text: row.baseName, value: row.baseCode });
              });
              this.salesOfficeList = list;
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(list);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
          });
      });
    },
    /**
     * 取引先マスタ参照APIを呼び出して返します。（取引先区分「メーカー」）
     */
    getSupplierList() {
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      //条件：取引先区分「メーカー」
      config.params.supplierType = appConfig.SUPPLIER_TYPE.maker;
      config.params.searchType = appConfig.DESTINATIONS_SEARCH_TYPE.normal;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.suppliers, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            let list = [];
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.resSuppliers.forEach((row) => {
                list.push({ text: row.supplierName, value: row.supplierCode });
              });
              resolve(list);
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(list);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
          });
      });
    },
    /**
     * 実績データファイル作成APIからURLを取得します。
     */
    fileOutput() {
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.deliveryDateFrom = this.deliveryDateCalFrom;
      config.params.deliveryDateTo = this.deliveryDateCalTo;
      config.params.salesOfficeCode = this.salesOfficeSelected;
      // 以下任意値
      if (this.mfgSelected.length > 0) {
        config.params.deliveryDestFactoryCodeList = this.mfgSelected;
      }
      if (this.supplierSelected.length > 0) {
        config.params.supplierCodeList = this.supplierSelected;
      }
      if (this.deliveryDest.length > 0) {
        config.params.deliveryDestAddressCode = this.deliveryDest;
      }

      this.$httpClient
        .secureGet(appConfig.API_URL.shipments, config)
        .then((response) => {
          const jsonData = JSON.parse(JSON.stringify(response.data));

          if (jsonData.resCom.resComCode == "000") {
            this.downloadUri = jsonData.resIdv.filePath;
            const urlNotParam = jsonData.resIdv.filePath.split("?")[0];
            this.downloadNm = decodeURI(urlNotParam.split("/")[3]);
            this.isUrlLink = true;
          } else {
            this.infoDialog.message = jsonData.resCom.resComMessageId
              ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
              : jsonData.resCom.resComMessage;
            this.infoDialog.title = "結果";
            this.infoDialog.isOpen = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          console.error("fileOutput error", error);
        });
    },
    /**
     * 与えられた日付から年月日を文字列で返します。
     */
    dateCalc(date) {
      const dt = new Date(date);
      const yyyy = dt.getFullYear();
      const mm = ("0" + (dt.getMonth() + 1)).slice(-2);
      const dd = ("0" + dt.getDate()).slice(-2);
      return yyyy + "-" + mm + "-" + dd;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },
    btnClear() {
      let date = new Date();
      // 搬入日付Toに当日を設定
      this.deliveryDateTo = this.dateCalc(date);
      // 搬入日付Fromに前日を設定
      date.setDate(date.getDate() - 1);
      this.deliveryDateFrom = this.dateCalc(date);
      this.salesOfficeSelected = sessionStorage.getItem("sales_office_code");
      this.mfgSelected = [];
      this.supplierSelected = [];
      this.deliveryDest = "";
    },
  },
  computed: {
    deliveryDateCalFrom() {
      return this.formatDate(this.deliveryDateFrom);
    },
    deliveryDateCalTo() {
      return this.formatDate(this.deliveryDateTo);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  h2 {
    padding: 0.5rem;
  }
}
#main-area {
  margin-top: 3rem;
  font-size: large;
}
.label {
  font-size: large;
}
#download-area {
  padding: 1rem;
  text-align: center;
}
#btn-file-output {
  margin: 1rem;
  background: #95b3d7;
  font-weight: bold;
}
#btn-clear {
  margin: 1rem;
  background: #95b3d7;
  font-weight: bold;
}
.label {
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 0rem;
  padding-right: 0;
  text-align: right;
  font-size: large;
  color: rgba(0, 0, 0, 0.6);
}

.list-single ::v-deep,
.list-multi ::v-deep,
.txt-single ::v-deep {
  padding-top: 0;
  padding-right: 0;
  font-size: large;
}

.list-single ::v-deep {
  div.v-input__slot {
    border-radius: 0.4rem;
  }
}
.list-single ::v-deep,
.list-multi ::v-deep {
  div.v-input__icon {
    background: #95b3d7;
    border-radius: 0.3rem;
  }
}
.txt-single ::v-deep {
  div.v-select__selection {
    width: 100%;
    text-align: center;
  }
  input {
    text-align: center;
    border-radius: 0.4rem;
  }
  .v-messages__message {
    line-height: 1.75rem;
    font-size: 130%;
  }
}

.require {
  font-size: large;
  color: red;
}
</style>
