<template>
  <div>
    <AppBar />
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <h2 id="lbl-screen-name">搬入先グループ一覧</h2>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col sm="3" id="base-code">
          <v-row>
            <v-col sm="2">
              <div class="search-label">拠点<span class="require">*</span></div>
            </v-col>
            <v-col sm="8">
              <v-form ref="baseCheck">
                <v-select
                  id="lst-base-code"
                  class="list-single"
                  v-model="baseCodeSelected"
                  :rules="[rules.baseIsEmpty]"
                  :error-messages="alertBaseMessage"
                  :items="baseCodeList"
                  :single-line="true"
                />
              </v-form>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="2" id="delivery-dest-grp-column">
          <v-row>
            <v-col sm="5">
              <div class="search-label">グループ<span class="require"></span></div>
            </v-col>
            <v-col sm="7">
              <v-text-field
                id="lst-delivery-dest-Grp-Code"
                v-model="deliveryDestGrpSelected"
                :single-line="true"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-col sm="3">
        <div id="access-group">
          <v-card-title> </v-card-title>
          <v-row>
            <v-col cols="4">
              <v-btn id="btn-search" class="api-btn" @click="clickSearch(false)"> 検索</v-btn>
            </v-col>
            <v-col>
              <v-btn id="btn-regist" class="api-btn" @click="registration()">新規登録</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col sm="3">
        <v-row>
          <div class="display-search-result-row">
            <!--検索結果表示-->
            <div class="text-label" style="float: left">
              <span>合計件数：{{ totalCount }}件</span>
            </div>
          </div>
        </v-row>
      </v-col>
      <v-col sm="3">
        <v-row>
          <!--表示件数切替ボタン-->
          <div style="float: right">
            <v-btn-toggle
              v-model="itemsPerPageBtn"
              color="blue darken-2"
              class="toggle black--text"
              right
            >
              <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                {{ 10 }}
              </v-btn>
              <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                {{ 50 }}
              </v-btn>
              <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                {{ 100 }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-row>
      </v-col>

      <v-data-table
        id="delivery-dest-grp-table"
        fixed-header
        :headers="headerItems"
        :items="deliveryDestGrpList"
        disable-filtering
        disable-pagination
        disable-sort
        :server-items-length="itemsPerPage"
        :hide-default-footer="true"
        height="650px"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <v-icon @click="openDeliveryDestGrpDetail(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
      </div>
    </v-container>
    <!--OKダイアログ-->
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
    />
  </div>
</template>

<script>
import AppBar from "../components/AppBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { appConfig } from "../assets/scripts/js/AppConfig";
import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper";
export default {
  name: "MstDeliveryDestGrpList",
  components: {
    AppBar,
    SimpleDialog,
    Loading,
  },
  props: {
    baseCodeBack: {},
    backFlag: {},
  },
  data: () => ({
    // 参照した拠点リスト
    baseCodeList: [],
    // 選択した拠点(次画面渡す)
    baseCodeSelected: "",
    // 参照した搬入先グループコード
    deliveryDestGrpCodeList: [],
    // 営業所コード（ログインユーザー拠点）
    salesOfficeCode: sessionStorage.getItem("sales_office_code"),
    // 選択した搬入先グループコード(次画面渡す)
    deliveryDestGrCode: "",
    deliveryDestGrpSelected: null,
    // 詳細画面に出す利用営業所(次画面渡す)
    salesOfficeNameAlt: "",
    // 搬入先グループ名(次画面渡す)
    deliveryDestGrpName: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    reqComPageLimit: "10",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    headerItems: [
      { text: "グループコード", value: "deliveryDestGrpCode", align: "center" },
      { text: "グループ名", value: "deliveryDestGrpName", align: "center" },
      { text: "搬入先件数", value: "count", align: "center" },
      { text: "更新日付", value: "updateDatetime", align: "center" },
      { text: "編集", value: "edit", align: "center" },
    ],
    deliveryDestGrpList: [],
    u_10: false,
    loadingCounter: 0,
    flug: "",
    // 拠点コード必須チェック
    alertBaseMessage: "",
    rules: {
      baseIsEmpty: (values) => !!values || messsageUtil.getMessage("P_MNG_003_001_E"),
    },
  }),
  methods: {
    /**
     * 初期化イベントです。
     */
    init() {
      this.getBaseCodeList();
      console.log("backFlag", this.$route.params.backFlag);
      console.log("baseCodeSelected", this.$route.params.baseCodeBack);
      if (this.$route.params.backFlag == true) {
        console.log("検索始まり", this.$route.params.baseCodeBack);
        this.baseCodeSelected = this.$route.params.baseCodeBack;
        if (this.$route.params.baseCodeBack == "") {
          // 処理なし
        } else {
          this.getDeliveryDestGrpList();
        }
      } else {
        // 処理なし
      }
    },
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.clickSearch();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.clickSearch();
    },
    /**
     * 拠点マスタ参照APIを呼び出します。（荷主拠点リスト）
     */
    getBaseCodeList() {
      console.debug("getBaseCodeList--start--");
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.baseType = appConfig.BASE_TYPE.factory;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.bases, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            //  正常時
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.resBases.forEach((row) => {
                list.push({
                  text: row.baseCode + "(" + row.baseNameAlt + ")",
                  value: row.baseCode,
                });
              });
              this.baseCodeList = list;
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("getBaseCodeList--end--");
          });
      });
    },

    /**
     * 搬入先グループヘッダAPIを呼び出します。（搬入先グループコードを集約した一覧）
     */
    getDeliveryDestGrpList() {
      console.debug("getDeliveryDestGrpList--start--");
      screenAuth.canAccess();
      this.tableData = [];
      const config = this.$httpClient.createGetApiRequestConfig();
      this.loadingCounter++;
      //営業所コード
      config.params.salesOfficeCode = this.salesOfficeCode;
      //拠点コード
      config.params.baseCode = this.baseCodeSelected;
      if (this.deliveryDestGrpSelected != null) {
        console.log("グループの中身", this.deliveryDestGrpSelected);
        if (this.deliveryDestGrpSelected == "") {
          this.deliveryDestGrpSelected == null;
        } else {
          //グループ
          config.params.deliveryDestGrpCode = this.deliveryDestGrpSelected + "%";
        }
      } else {
        // リクエストに含めない
      }

      //ページ内表示数
      config.params.reqComPageLimit = this.reqComPageLimit;
      //ページ数
      config.params.reqComPageIndex = this.page;
      //ページ内表示数
      config.params.reqComPageLimit = this.itemsPerPage;

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.destinationgrouplist, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // const list = [];
            //  正常時
            if (jsonData.resCom.resComCode == "000") {
              console.log("API接続に成功しました。");
              console.debug(jsonData.resCom.resComCount);
              //
              this.deliveryDestGrpList = jsonData.resIdv.resDestinationGroups;
              this.tableData = jsonData.resIdv.resDestinationGroups;
              this.setDeliveryDestGrpList();
              // ページング
              this.totalCount = jsonData.resCom.resComCount;
              this.pageCount = this.totalCount / this.itemsPerPage;
              if (this.totalCount % this.itemsPerPage != 0) {
                this.pageCount++;
              }
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            console.debug(jsonData.resIdv.resDestinationGroups);
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("getBaseCodeList--end--");
          });
      });
    },

    /**
     * データテーブルに搬入先グループ一覧をマッピングします。
     */
    setDeliveryDestGrpList() {
      const list = [];
      // const dataList = [];
      this.tableData.forEach((row) => {
        list.push({
          //グループコード
          deliveryDestGrpCode: row.deliveryDestGrpCode,
          //グループ名
          deliveryDestGrpName: row.deliveryDestGrpName,
          //搬入先件数
          count: row.count,
          //更新日付
          updateDatetime: dateTimeHelper.toStringDate(
            "YYYY/MM/DD hh:mm",
            new Date(row.updateDatetime)
          ),
          //利用営業所
          salesOfficeName: row.salesOfficeName,
          //営業所コード
          salesOfficeCode: row.salesOfficeCode,
          //拠点
          baseNameAlt: row.baseNameAlt,
          //拠点コード
          baseCode: row.baseCode,
        });
      });
      this.deliveryDestGrpList = list;
    },
    /**
     * 搬入先グループマスタ詳細画面に遷移します。
     */
    openDeliveryDestGrpDetail(item) {
      // 次画面の表示を変更するフラグ:詳細
      this.flag = "details";
      console.log("test", item);
      this.$router.push({
        name: "DeliveryDestGrpDetails",
        params: {
          item: item,
          flagPass: this.flag,
        },
        props: true,
      });
    },
    /**
     * 検索ボタン押下時のイベントです。
     */
    clickSearch() {
      // 必須チェック
      if (this.baseCodeSelected == "") {
        this.alertBaseMessage = messsageUtil.getMessage("P_MNG_003_001_E");
      } else {
        this.alertBaseMessage = "";
        this.getDeliveryDestGrpList(false);
      }
    },
    /**
     * 新規登録ボタン押下時のイベントです。
     */
    registration() {
      // 次画面の表示を変更するフラグ:新規登録
      this.flag = "regist";
      console.log("flaggggggg", this.flag);
      this.$router.push({
        name: "DeliveryDestGrpDetails",
        params: {
          salesOfficeCodePass: this.salesOfficeCode,
          //salesOfficeName: this.salesOfficeNameAlt,
          flagPass: this.flag,
        },
      });
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    page: function (newValue, oldValue) {
      console.log("pageCount", newValue, oldValue);
      this.page = newValue;
      this.clickSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  h2 {
    padding: 0.5rem;
  }
}

#delivery-dest-grp-column {
  min-width: 20rem !important;
}

#base-code {
  z-index: 50;
}

#delivery-dest-grp-table {
  &.v-data-table--fixed-header ::v-deep {
    th {
      padding: 0.1rem;
      background: #fbe9db;
    }
  }
}

.v-data-table--fixed-header ::v-deep {
  table {
    border-collapse: collapse !important;
    width: 70% !important;
  }

  td {
    height: 3rem !important;
  }

  tr:nth-child(odd) {
    background-color: #dde5f0;
    font-size: medium !important;
  }

  th {
    background-color: #fbe9db;
    font-size: medium !important;

    &.diff {
      background-color: $color-diff !important;
    }

    &.warning {
      background-color: $color-warning !important;
    }

    &:nth-child(1),
    &:nth-child(2) {
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 21;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }

    &:last-child {
      border-right: 1px solid $border-color;
    }

    &:not(:nth-child(-n + 2)) {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }

    &:nth-child(1) {
      left: 0;
    }

    &:nth-child(2) {
      left: 12rem;
    }
  }
  td {
    $detail-color: rgb(137, 137, 238);

    button.detail-colmun {
      width: 100%;
      height: 100%;
      display: block !important;
      color: blue;
      text-decoration: underline $detail-color 2px;
      box-sizing: border-box;

      &:hover {
        background-color: #afcfe4;
        outline: dashed $detail-color 2px;
      }
    }

    &:nth-child(1),
    &:nth-child(2) {
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 20;

      button::before,
      div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }

    &:nth-child(1) {
      left: 0;
      font-size: large;
    }

    &:nth-child(2) {
      left: 12rem;
    }

    &:last-child {
      border-right: 1px solid $border-color;
    }
  }
}

#table-guide {
  margin-top: 4.5rem;

  #lbl-namber-of-sheets {
    width: 100%;
    text-align: center;
  }
}

.require {
  font-size: large;
  color: red;
  display: inline-block;
}

.text-center pt-2 {
  left: 20 !important;
}

.list-single ::v-deep {
  div.v-input__slot {
    border-radius: 0.4rem;
  }
}

.list-single ::v-deep,
.list-multi ::v-deep {
  div.v-input__icon {
    background: #95b3d7;
    border-radius: 0.3rem;
  }
}

.v-btn.v-btn--has-bg {
  background: $color-obj-base;

  &.api-btn {
    width: 7rem;
    margin: 0 0 0 auto;
  }
}
</style>
