<template>
  <div>
    <AppBar />
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <h2 id="lbl-screen-name">搬入先グループ詳細</h2>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col sm="3" id="delivery-dest-grp-base">
          <v-row>
            <v-col sm="3">
              <div class="search-label" disabled="isDisabled">利用営業所</div>
            </v-col>
            <v-col sm="7">
              <v-text-field
                id="sales-office-code"
                v-model="salesOfficeCodeName"
                :single-line="true"
                :disabled="true"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="2" id="delivery-dest-grp-code">
          <v-row>
            <v-col sm="4">
              <div class="search-label" v-if="flag === 'details'">グループ</div>
              <div class="search-label" v-if="flag === 'regist'">
                グループ<span class="require">*</span>
              </div>
            </v-col>
            <v-col sm="8">
              <v-text-field
                v-if="flag === 'details'"
                id="lst-delivery-dest-Grp-Code"
                v-model="deliveryDestGrpCodeSelected"
                :single-line="true"
                :disabled="true"
              />
              <v-text-field
                v-if="flag === 'regist'"
                id="lst-delivery-dest-Grp-Code"
                v-model="deliveryDestGrpCodeSelected"
                :rules="[rules.grpCodeIsEmpty]"
                :error-messages="alertGrpCodeMessage"
                :single-line="true"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="3" id="delivery-dest-grp-name">
          <v-row>
            <v-col sm="3">
              <div class="search-label" v-if="flag === 'details'">グループ名</div>
              <div class="search-label" v-if="flag === 'regist'">
                グループ名<span class="require">*</span>
              </div>
            </v-col>
            <v-col sm="7">
              <v-text-field
                id="lst-delivery-dest-Grp-Name"
                v-model="deliveryDestGrpNameSelected"
                :rules="[rules.grpNameIsEmpty]"
                :error-messages="alertGrpNameMessage"
                :single-line="true"
                @change="deliveryDestGrpNameDiff"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--追加項目-->
      <v-row>
        <v-col sm="2" id="base-code">
          <div class="search" v-if="flag === 'details'">拠点<span class="require">*</span></div>
          <div class="search" v-if="flag === 'regist'">拠点<span class="require">*</span></div>
          <v-select
            id="lst-base-code"
            class="list-single"
            v-model="baseNameSelected"
            :rules="[rules.baseIsEmpty]"
            :error-messages="alertBaseMessage"
            :items="baseCodeList"
            :single-line="true"
          />
        </v-col>
        <v-col sm="1" id="plan-code">
          計画コード<span class="require">*</span>
          <v-autocomplete
            id="lst-plan-Code"
            class="list-single"
            v-model="planCodeSelected"
            :items="planCodeList"
            :single-line="true"
            @change="(event) => selectPlancode(event)"
            :search-input.sync="planSearch"
            :rules="[rules.planIsEmpty]"
            :error-messages="alertPlanMessage"
          />
        </v-col>
        <v-col sm="1" id="delivery-dest-address">
          搬入先アドレス<span class="require">*</span>
          <v-select
            id="lst-delivery-dest-Grp-Code"
            class="list-single"
            v-model="deliveryDestAddressSelected"
            :rules="[rules.deliveryDestIsEmpty]"
            :error-messages="alertDeliveryDestMessage"
            :items="deliveryDestAddressList"
            :single-line="true"
          />
        </v-col>
        <v-col sm="2" id="next-sales-office-code">
          中継先営業所
          <v-select
            id="lst-next-sales-office-code"
            class="list-single"
            v-model="nextSalesOfficeCodeSelected"
            :items="nextSalesOfficeCodeList"
            :single-line="true"
          />
        </v-col>
        <v-col sm="1" id="serch-delivery-dest-grp-column">
          <div id="access-group">
            <v-col>
              <v-btn id="btn-Add" class="api-btn" @click="clickAdd()">追加</v-btn>
            </v-col>
          </div>
        </v-col>
      </v-row>

      <div btnbtn>
        <v-row>
          <v-col cols="1">
            <v-btn id="btn-Add" class="api-btn" @click="clickBack()">戻る</v-btn>
          </v-col>
          <v-col>
            <v-btn
              id="btn-regist"
              class="api-btn"
              @click="clickRegist()"
              :disabled="!activateSubmit"
              >登録</v-btn
            >
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col sm="3">
          <div class="search-label">搬入先数：{{ this.totalCount }}件</div>
        </v-col>
      </v-row>

      <v-data-table
        id="delivery-dest-grp-table"
        fixed-header
        :headers="headerItems"
        :items="deliveryDestGrpList"
        disable-filtering
        disable-pagination
        disable-sort
        :hide-default-footer="true"
        height="500px"
      >
        <template v-slot:[`item.createDatetime`]="{ item }">
          <template v-if="item.createDatetime">
            {{ item.createDatetime }}
          </template>
          <template v-else>
            <span class="not-find"> 登録がありません </span>
          </template>
        </template>
        <!-- <td :class="headerItems[5]"> -->
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">中継先営業所<br /></div>
          </template>
        </v-tooltip>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon @click="deleteDeliveryDestGrpDetail(item)" text>mdi-delete</v-icon>
        </template>
        <!-- </td> -->
      </v-data-table>
    </v-container>
    <!-- 登録時確認ダイアログ-->
    <ConfirmDialog
      :isShow.sync="isRegistDeliveryDestGrp"
      :message="infoDialog.message"
      :okAction="registForDeliveryDestGrp"
    />

    <!--OKダイアログ-->
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
    />
  </div>
</template>

<script>
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import AppBar from "../components/AppBar.vue";
import Loading from "@/components/loading";
import { appConfig } from "../assets/scripts/js/AppConfig";
import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper";
export default {
  name: "MstDeliveryDestGrpListDetails",
  components: {
    AppBar,
    SimpleDialog,
    ConfirmDialog,
    Loading,
  },
  props: {
    item: {},
    flagPass: {},
    salesOfficeCodePass: {},
    salesOfficeName: {},
  },
  data: () => ({
    // 参照した拠点リスト
    baseCodeList: [],
    // 選択した拠点
    baseNameSelected: "",
    // 選択した拠点コード
    baseCodeSelected: "",
    // 営業所コード（ログインユーザー拠点）
    salesOfficeCode: "",
    // 営業所名（ログインユーザー拠点）
    salesOfficeCodeName: "",
    // 選択した搬入先グループコード
    deliveryDestGrpCodeSelected: "",
    // 選択した搬入先グループ名
    deliveryDestGrpNameSelected: "",
    // 搬入先グループ名初期値
    deliveryDestGrpName: "",
    // 計画コードプルダウンリスト
    planCodeList: [],
    // 選択した計画コード
    planCodeSelected: "",
    // 搬入先アドレスプルダウンリスト
    deliveryDestAddressList: [],
    // 選択した搬入先アドレス
    deliveryDestAddressSelected: [],
    // 参照した営業所(中継営業所リスト)
    nextSalesOfficeCodeList: [],
    // 選択した搬入先グループ名
    nextSalesOfficeCodeSelected: "",
    headerItems: [
      { text: "No", value: "count", align: "center" },
      { text: "拠点", value: "baseCode", align: "center" },
      { text: "計画コード", value: "planCode", align: "center" },
      { text: "搬入先アドレス", value: "deliveryDestAddress", align: "center" },
      { text: "搬入先アドレス登録日時", value: "createDatetime", align: "center" },
      { text: "中継先営業所", value: "nextSalesOfficeCode", align: "center" },
      // { text: "削除", value: "delete", align: "center" },
    ],
    // 詳細リスト
    deliveryDestGrpList: [],
    // 比較用リスト
    comparisonList: [],
    // 画面項目の変更フラグ
    flag: "",
    // 重複チェック返却値
    doubleCheck: [],
    // 登録追加用リスト
    registDeliveryDestGrpList: [],
    // 詳細リスト＋登録追加用リスト
    //deliveryDestGrpListDiff: [],
    // 件数
    totalCount: 0,
    // 共通ダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // 登録ボタンダイアログ
    isRegistDeliveryDestGrp: false,
    // 処理区分
    opeType: "",
    // 戻るフラグ
    backFlag: true,
    loadingCounter: 0,
    comparisonLength: "",
    // 拠点コード必須チェック
    alertBaseMessage: "",
    // 計画コード必須チェック
    alertPlanMessage: "",
    // 搬入先アドレス必須チェック
    alertDeliveryDestMessage: "",
    alertGrpCodeMessage: "",
    alertGrpNameMessage: "",
    deliveryDestGrpNameDiff: "",
    planSearch: "",
    rules: {
      baseIsEmpty: (values) => !!values || messsageUtil.getMessage("P_MNG_003_001_E"),
      planIsEmpty: (values) => !!values || messsageUtil.getMessage("P_MNG_003_002_E"),
      deliveryDestIsEmpty: (values) => !!values || messsageUtil.getMessage("P_MNG_003_003_E"),
      grpCodeIsEmpty: (values) => !!values || messsageUtil.getMessage("P_MNG_003_004_E"),
      grpNameIsEmpty: (values) => !!values || messsageUtil.getMessage("P_MNG_003_005_E"),
    },
  }),
  methods: {
    /**
     * 初期化イベントです。
     */
    init() {
      console.log("params", this.$route.params);
      // 前画面のからのパラメータを詰めます。

      // 画面によって詰める項目や使うAPIを変えます
      this.flag = this.$route.params.flagPass;
      console.log("初期フラグ値", this.$route.params.flagPass);
      if (this.flag == "details") {
        // 詳細
        this.salesOfficeCodeName = this.$route.params.item.salesOfficeName;
        // this.baseNameSelected = this.$route.params.item.baseNameAlt;
        this.baseCodeSelected = this.$route.params.item.baseCode;
        // this.baseNameSelected = this.baseCodeSelected + "(" + this.baseNameSelected + ")";
        this.deliveryDestGrpCodeSelected = this.$route.params.item.deliveryDestGrpCode;
        this.deliveryDestGrpNameSelected = this.$route.params.item.deliveryDestGrpName;
        this.salesOfficeCode = this.$route.params.item.salesOfficeCode;
        console.log("営業所コード", this.salesOfficeCode);

        // 搬入先グループマスタ詳細を参照します。
        this.getDeliveryDestGrp();
      } else {
        // 新規登録
        this.salesOfficeCode = this.$route.params.salesOfficeCodePass;
        this.salesOfficeCodeName = this.$route.params.salesOfficeName;
        console.log("営業所コード", this.salesOfficeCode);
        // 営業所
        if (sessionStorage.getItem("sales_office_code") == "018") {
          this.salesOfficeCodeName = "日本梱包運輸倉庫株式会社狭山営業所";
        } else if (sessionStorage.getItem("sales_office_code") == "028") {
          this.salesOfficeCodeName = "日本梱包運輸倉庫株式会社浜松営業所";
        } else if (sessionStorage.getItem("sales_office_code") == "038") {
          this.salesOfficeCodeName = "日本梱包運輸倉庫株式会社小川営業所";
        } else if (sessionStorage.getItem("sales_office_code") == "058") {
          this.salesOfficeCodeName = "日本梱包運輸倉庫株式会社宇都宮営業所";
        } else if (sessionStorage.getItem("sales_office_code") == "060") {
          this.salesOfficeCodeName = "日本梱包運輸倉庫株式会社大津営業所";
        } else if (sessionStorage.getItem("sales_office_code") == "081") {
          this.salesOfficeCodeName = "日本梱包運輸倉庫株式会社鈴鹿物流センター営業所";
        } else if (sessionStorage.getItem("sales_office_code") == "106") {
          this.salesOfficeCodeName = "日本梱包運輸倉庫株式会社寄居出張所";
        }
        console.log("flag", this.flag);
      }

      // 両画面で実行
      // 拠点コードを取得します
      this.getBaseCodeList();
      // 計画コードを取得します
      this.serchPlanCodeList();
      //グループ名初期値の設定
      this.deliveryDestGrpName = this.deliveryDestGrpNameSelected;
      //中継先営業所リスト参照
      this.getNextSalesOfficeCode();
    },

    /**
     * 搬入先グループマスタ詳細を参照します。
     */
    getDeliveryDestGrp() {
      console.debug("getDeliveryDestGrp--start--");
      screenAuth.canAccess();
      this.tableData = [];
      const config = this.$httpClient.createGetApiRequestConfig();
      this.loadingCounter++;
      //営業所コード
      config.params.salesOfficeCode = sessionStorage.getItem("sales_office_code");
      //拠点コード
      config.params.baseCode = this.baseCodeSelected;
      //グループコード
      config.params.deliveryDestGrpCode = this.deliveryDestGrpCodeSelected;

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.destinationgroup, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            //  正常時
            if (jsonData.resCom.resComCode == "000") {
              console.log("API接続に成功しました。");
              console.debug(jsonData.resCom.resComCount);
              this.deliveryDestGrpList = jsonData.resIdv.resDestinationGroups;
              this.totalCount = jsonData.resCom.resComCount;
              console.log("整形前", jsonData.resIdv.resDestinationGroups);
              // 時間を整形します。
              for (var l = 0; l < this.deliveryDestGrpList.length; l++) {
                this.deliveryDestGrpList[l].updateDatetime = this.deliveryDestGrpList[
                  l
                ].updateDatetime.replace("T", " ");
                this.deliveryDestGrpList[l].updateDatetime = this.deliveryDestGrpList[
                  l
                ].updateDatetime.replaceAll("-", "/");
              }
              // 処理区分(更新)追加
              for (var i = 0; i < this.deliveryDestGrpList.length; i++) {
                this.deliveryDestGrpList[i].opeType = "02";
              }
              console.log("整形後", this.deliveryDestGrpList);
              // 登録ボタン活性化チェック用に参照後のリストの長さを保存
              const length = this.deliveryDestGrpList.length;
              this.comparisonLength = length;
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            console.debug(jsonData.resIdv.resDestinationGroups);
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("getBaseCodeList--end--");
          });
      });
    },
    /**
     * 拠点マスタ参照APIを呼び出します。（荷主拠点リスト）
     */
    getBaseCodeList() {
      console.debug("getBaseCodeList--start--");
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.baseType = appConfig.BASE_TYPE.factory;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.bases, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            //  正常時
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.resBases.forEach((row) => {
                list.push({
                  text: row.baseCode + "(" + row.baseNameAlt + ")",
                  value: row.baseCode,
                });
              });
              this.baseCodeList = list;
              console.log("baseCodeList", this.baseCodeList);
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("getBaseCodeList--end--");
          });
      });
    },

    /**
     * 拠点マスタ参照API（営業所）を呼び出して返します。（拠点区分：日梱 中継営業所リスト）
     */
    getNextSalesOfficeCode() {
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      // 条件：システム運用会社（日梱拠点）
      config.params.baseType = appConfig.BASE_TYPE.systemOperationCompany;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.bases, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.resBases.forEach((row) => {
                list.push({ text: row.baseNameAlt, value: row.baseCode });
              });
              this.nextSalesOfficeCodeList = list;
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(list);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
          });
      });
    },
    // /**
    //  * グループ名を変更した際のイベントです。
    //  */
    // deliveryDestGrpNameDiff() {
    //   console.log("グループ名の変更イベント", this.deliveryDestGrpList);
    //   for (var l = 0; l < this.deliveryDestGrpList.length; l++) {
    //     this.deliveryDestGrpList[l].deliveryDestGrpName = this.deliveryDestGrpNameSelected;
    //     console.log("APIリクエスト", this.deliveryDestGrpList[l].deliveryDestGrpName);
    //   }
    // },

    /**
     * 計画コードプルダウンから1つを選択した際のイベントです。
     */
    // selectPlancode() {
    //   this.serchDeliveryDestAddressList();
    // },

    /**
     * プルダウンチェンジイベント
     */
    selectPlancode(value) {
      // 既存品番/品名を選択時
      let val = this.planCodeList.find((v) => v.value == value);
      console.debug("****val_text****", val.text);
      console.debug("****val_value****", val.value);
      if ((val ?? false) && value != "") {
        //this.productHint = val.text;
        this.planSearch = val.value;
      }
      this.planCodeList = this.planCodeList.filter((v) => v.value == value);
      this.serchDeliveryDestAddressList();
    },

    /**
     * 追加ボタンを押下した際のイベントです。
     */
    clickAdd() {
      console.log("flag", this.flag);
      console.log("clickAdd--start--");
      // 必須チェック
      console.log("deliveryDestGrpCodeSelected", this.deliveryDestGrpCodeSelected);

      // 拠点
      if (this.baseNameSelected == "") {
        this.alertBaseMessage = messsageUtil.getMessage("P_MNG_003_001_E");
      } else {
        this.alertBaseMessage = "";
      }
      // 計画コード
      if (this.planCodeSelected == "") {
        this.alertPlanMessage = messsageUtil.getMessage("P_MNG_003_002_E");
      } else {
        this.alertPlanMessage = "";
      }
      // 搬入先アドレス
      if (this.deliveryDestAddressSelected == "") {
        this.alertDeliveryDestMessage = messsageUtil.getMessage("P_MNG_003_003_E");
      } else {
        this.alertDeliveryDestMessage = "";
      }

      // 登録時の必須チェック
      //グループ
      if (this.flag == "regist" && this.deliveryDestGrpCodeSelected == "") {
        this.alertGrpCodeMessage = messsageUtil.getMessage("P_MNG_003_004_E");
      } else {
        this.alertGrpCodeMessage = "";
      }
      //グループ名
      if (this.flag == "regist" && this.deliveryDestGrpNameSelected == "") {
        this.alertGrpNameMessage = messsageUtil.getMessage("P_MNG_003_005_E");
      } else {
        this.alertGrpNameMessage = "";
      }

      // 必須が全て入力されている場合API
      if (
        this.baseNameSelected != "" &&
        this.planCodeSelected != "" &&
        this.deliveryDestAddressSelected != "" &&
        this.flag == "details"
      ) {
        this.doubleCheckForDeliveryDestGrp();
      }

      // 必須が全て入力されている場合API(新規登録)
      if (
        this.baseNameSelected != "" &&
        this.planCodeSelected != "" &&
        this.deliveryDestAddressSelected != "" &&
        this.deliveryDestGrpCodeSelected != "" &&
        this.deliveryDestGrpNameSelected != "" &&
        this.flag == "regist"
      ) {
        this.doubleCheckForDeliveryDestGrp();
      }
    },

    /**
     * 登録ボタン
     */
    clickRegist() {
      console.log("clickRegist--start--");
      this.infoDialog.message = "登録を実行します。";
      this.infoDialog.title = "結果";
      this.isRegistDeliveryDestGrp = true;
      this.getDeliveryDestGrp;
    },

    /**
     * 計画コード参照API
     */
    serchPlanCodeList() {
      console.debug("serchPlanCodeList--start--");
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.getPlanCodeList, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            // const planlist = [];
            //  正常時
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.planCodeList.forEach((row) => {
                list.push({
                  text: row.planCode,
                  value: row.planCode,
                });
              });
              this.planCodeList = list;

              console.log("planCodeList", this.planCodeList);
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("getBaseCodeList--end--");
          });
      });
    },

    /**
     * 搬入先アドレス参照API
     */
    serchDeliveryDestAddressList() {
      console.debug("serchDeliveryDestAddressList() {--start--");
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      //計画コード
      config.params.planCode = this.planCodeSelected;
      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.getDeliveryDestAddressList, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            const list = [];
            //  正常時
            if (jsonData.resCom.resComCode == "000") {
              jsonData.resIdv.deliveryDestAddressList.forEach((row) => {
                list.push({
                  text: row.deliveryDestAddress,
                  value: row.deliveryDestAddress,
                });
              });
              this.deliveryDestAddressList = list;
              console.log("deliveryDestAddressList", this.deliveryDestAddressList);
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("serchDeliveryDestAddressList--end--");
          });
      });
    },

    /**
     * 重複チェックAPI
     */
    doubleCheckForDeliveryDestGrp() {
      console.log("doubleCheck --start--");
      screenAuth.canAccess();
      const config = this.$httpClient.createGetApiRequestConfig();
      //営業所コード
      config.params.salesOfficeCode = this.salesOfficeCode;
      //計画コード
      config.params.planCode = this.planCodeSelected;
      //搬入先アドレス
      config.params.deliveryDestAddress = this.deliveryDestAddressSelected;

      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.destinationgroupsCheck, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            console.log("jsonData", jsonData);
            //  正常時
            if (jsonData.resCom.resComCode == "000") {
              // 返却地があるかどうか
              const listLength = jsonData.resIdv.resDestinationGroups.length;
              console.log("listLength", listLength);

              // 搬入先グループコードがレスポンスにない場合（listがない場合）
              if (listLength == 0) {
                this.totalCount = this.totalCount + 1;
                this.registDeliveryDestGrpList.push({
                  // No
                  count: this.totalCount,
                  // 計画コード
                  planCode: this.planCodeSelected,
                  // 搬入先アドレス
                  deliveryDestAddress: this.deliveryDestAddressSelected,
                  // 拠点
                  baseCode: this.baseNameSelected.substr(0, 1),
                  // 営業所
                  salesOfficeCode: this.salesOfficeCode,
                  // グループ
                  deliveryDestGrpCode: this.deliveryDestGrpCodeSelected,
                  // グループ名
                  deliveryDestGrpName: this.deliveryDestGrpNameSelected,
                  // 中継先営業所
                  nextSalesOfficeCode: this.nextSalesOfficeCodeSelected,
                  // 区分フラグ
                  opeType: "01",
                });
                // 新規登録の場合
                if (this.flag == "regist") {
                  // 処理区分を変える
                  for (var j = 0; j < this.registDeliveryDestGrpList.length; j++) {
                    this.registDeliveryDestGrpList[j].opeType = "03";
                  }
                  this.deliveryDestGrpList = this.registDeliveryDestGrpList;
                  // 追加登録の場合
                } else {
                  const count = this.registDeliveryDestGrpList.length - 1;
                  console.log("count", count);
                  console.log("詳細リストの長さ", this.deliveryDestGrpList.length);

                  // ループ用変数(詳細リストの件数ループ)
                  var listLoop = this.deliveryDestGrpList.length;
                  for (var i = 0; i < listLoop; i++) {
                    console.log(
                      "this.deliveryDestGrpList[i].getBaseCode",
                      this.deliveryDestGrpList[i].baseCode
                    );
                    console.log(
                      "this.registDeliveryDestGrpList[count]",
                      this.registDeliveryDestGrpList[count].baseCode
                    );
                    if (
                      this.deliveryDestGrpList[i].baseCode ==
                        this.registDeliveryDestGrpList[count].baseCode &&
                      this.deliveryDestGrpList[i].planCode ==
                        this.registDeliveryDestGrpList[count].planCode &&
                      this.deliveryDestGrpList[i].deliveryDestAddress ==
                        this.registDeliveryDestGrpList[count].deliveryDestAddress
                    ) {
                      console.log("存在するため含めません！！！！", this.deliveryDestGrpList);
                      this.infoDialog.message = "既に追加済みです";
                      this.infoDialog.title = "エラー";
                      this.infoDialog.isOpen = true;
                      // break;
                      // 異なる場合含める
                    } else {
                      console.log("詳細リストの長さ", this.deliveryDestGrpList.length);
                      if (i == this.deliveryDestGrpList.length - 1) {
                        console.log("プッシュします", this.deliveryDestGrpList);
                        this.deliveryDestGrpList.push(this.registDeliveryDestGrpList[count]);
                      }
                    }
                  }
                  // this.deliveryDestGrpList.push(this.registDeliveryDestGrpList[count]);
                }
              }
              // レスポンスがある場合（listがある場合）
              else {
                console.log("doubleCheck---warn!!!", this.doubleCheck);
                console.log("搬入先", jsonData.resIdv.resDestinationGroups[0].deliveryDestGrpCode);
                this.infoDialog.message =
                  "搬入先グループ" +
                  jsonData.resIdv.resDestinationGroups[0].deliveryDestGrpCode +
                  "に登録があります。";
                this.infoDialog.title = "エラー";
                this.infoDialog.isOpen = true;
              }
              console.log("doubleCheck", this.doubleCheck);
            } else {
              // 複数業務エラーが発生しうるのであればrejectすること。
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("doubleCheck--end--");
          });
      });
    },

    /**
     * 計画コードリストボックス検索
     */
    setSrhArrival() {
      for (var i = 0; i < this.planCodeList.length; i++) {
        if (this.planCodeList[i].value == this.planCodeSelected) {
          return this.planCodeList[i].name;
        }
      }
    },

    /**
     * リスト存在チェック
     */
    deliveryDestGrpListCheck() {},

    /**
     * 搬入先グループマスタ登録更新API
     */
    registForDeliveryDestGrp() {
      console.debug("registForDeliveryDestGrp--start--");
      screenAuth.canAccess();
      const body = this.$httpClient.createRequestBodyConfig();

      //bodyに必要な値を詰める
      // 登録の場合
      if (this.flag == "regist") {
        // 登録更新情報リスト
        body.reqIdv.reqDeliveryDestGrpCodeList = this.registDeliveryDestGrpList;
        console.log("リクエストbody", body);
        console.log("リクエストbody.reqIdv", body.reqIdv.reqDeliveryDestGrpCodeList);
        console.log("フラグ", this.flag);
        // 更新の場合
      } else {
        // 登録更新情報リスト
        body.reqIdv.reqDeliveryDestGrpCodeList = this.deliveryDestGrpList;
        console.log("リクエストbody", body);
        console.log("リクエストbody.reqIdv", body.reqIdv.reqDeliveryDestGrpCodeList);
        console.log("フラグ", this.flag);
      }

      this.loadingCounter++;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .securePost(appConfig.API_URL.deliverydestgrp, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            console.log("jsonData", jsonData);
            console.log("bbbbbbbbbbbbbb", jsonData);
            // const list = [];
            //  正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = "登録が完了いたしました。";
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            } else if (jsonData.resCom.resComCode == "004") {
              // 業務エラーが発生する場合。
              console.log("業務エラー", jsonData);
              this.infoDialog.message =
                "登録しようとした搬入先グループは既に登録済みです。詳細画面から追加してください。";
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            } else {
              // 業務エラーが発生する場合。
              console.log("業務エラー", jsonData);
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
            }
            //resolve(response);
          })
          .catch((resoleve) => {
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            reject(resoleve);
          })
          .finally(() => {
            this.loadingCounter--;
            console.debug("clickRegist--end--");
          });
      });
    },

    /**
     * 登録完了後の画面遷移イベントです。
     */
    home() {
      this.$router.push("master_manage_list");
    },
    /**
     * 戻るボタンを押下時のイベントです。
     */
    clickBack() {
      this.$router.push({
        name: "MasterManageList",
        params: {
          baseCodeBack: this.baseCodeSelected,
          backFlag: this.backFlag,
        },
      });
      console.log("渡すフラグ", this.backFlag);
      console.log("渡す拠点", this.baseCodeSelected);
    },

    /**
     * 削除ボタンを押下時のイベントです
     */
    deleteDeliveryDestGrpDetail(item) {
      item.deleteFlag = 1;
      console.log("削除イベント", item);

      // 配列の何処を削除したか
      const index = item.count - 1;
      // 表示されているリストから削除リストに挿入
      this.deliveryDestGrpList[index];
      // 表示されているリストから削除
    },

    /**
     * データテーブルに搬入先グループ一覧をマッピングします。
     */
    setDeliveryDestGrpList() {
      const list = [];
      const dataList = [];
      this.tableData.forEach((row) => {
        list.push({
          //No
          count: row.count,
          //計画コード
          planCode: row.planCode,
          //搬入先アドレス
          deliveryDestAddress: row.deliveryDestAddress,
          //搬入先アドレス登録日時
          createDatetime: dateTimeHelper.toStringDate(
            "YYYY/MM/DD hh:mm",
            new Date(row.createDatetime)
          ),
          //削除
          //col03:
          delete: dataList,
        });
      });
      this.deliveryDestGrpList = list;
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    // 登録ボタン制御
    activateSubmit() {
      console.log("登録ボタン制御開始", this.comparisonLength);
      console.log("追加されたリスト", this.deliveryDestGrpList.length);
      console.log("グループコード", this.deliveryDestGrpCodeSelected);
      console.log("グループ名", this.deliveryDestGrpNameSelected);
      if (this.flag == "details") {
        // 追加の場合
        if (
          this.deliveryDestGrpList.length != this.comparisonLength ||
          this.deliveryDestGrpName != this.deliveryDestGrpNameSelected
        ) {
          console.log("活性化", this.deliveryDestGrpNameSelected);
          return true;
        } else {
          return false;
        }
      } else {
        // 登録の場合
        if (this.deliveryDestGrpList.length != this.comparisonList.length) {
          console.log("活性化", this.deliveryDestGrpNameSelected);
          return true;
        } else {
          return false;
        }
      }
    },
  },
  watch: {
    planCodeSelected() {},

    planSearch(val) {
      if (val ?? false) {
        console.debug("serchPlanCodeList--start--");
        screenAuth.canAccess();
        const config = this.$httpClient.createGetApiRequestConfig();
        //計画コード
        config.params.planCode = val;
        this.loadingCounter++;
        console.log("APIに渡す計画コード", val);
        return new Promise((resolve, reject) => {
          this.$httpClient
            .secureGet(appConfig.API_URL.getPlanCodeList, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              const list = [];
              //  正常時
              if (jsonData.resCom.resComCode == "000") {
                jsonData.resIdv.planCodeList.forEach((row) => {
                  list.push({
                    text: row.planCode,
                    value: row.planCode,
                  });
                });
                this.planCodeList = list;

                console.log("planCodeList", this.planCodeList);
              } else {
                // 複数業務エラーが発生しうるのであればrejectすること。
                this.infoDialog.message = jsonData.resCom.resComMessageId
                  ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                  : jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
              }
              resolve(response);
            })
            .catch((resoleve) => {
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              reject(resoleve);
            })
            .finally(() => {
              this.loadingCounter--;
              console.debug("getBaseCodeList--end--");
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  h2 {
    padding: 0.5rem;
  }
}

// #delivery-dest-grp-column {
//   min-width: 20rem !important;
// }

#base-code {
  z-index: 50;
}
#plan-code {
  z-index: 50;
}
#delivery-dest-address {
  z-index: 50;
}
#next-sales-office-code {
  z-index: 50;
}

#delivery-dest-grp-table {
  &.v-data-table--fixed-header ::v-deep {
    th {
      padding: 0.1rem;
      background: #fbe9db;
    }
  }
}

.v-data-table--fixed-header ::v-deep {
  table {
    border-collapse: collapse !important;
    width: 70% !important;
  }

  td {
    height: 5rem !important;
  }

  tr:nth-child(odd) {
    background-color: #dde5f0;
    font-size: medium !important;
  }

  th {
    background-color: #fbe9db;
    font-size: medium !important;

    &.diff {
      background-color: $color-diff !important;
    }

    &.warning {
      background-color: $color-warning !important;
    }

    &:nth-child(1) {
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 50;
      width: 15%;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
    &:nth-child(2) {
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 50;
      width: 15%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }

    &:last-child {
      border-right: 1px solid $border-color;
    }

    &:not(:nth-child(-n + 2)) {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 21c;
        border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }
  }
  td {
    $detail-color: rgb(137, 137, 238);

    button.detail-colmun {
      width: 100%;
      height: 100%;
      display: block !important;
      color: blue;
      text-decoration: underline $detail-color 2px;
      box-sizing: border-box;

      &:hover {
        background-color: #afcfe4;
        outline: dashed $detail-color 2px;
      }
    }

    &:nth-child(1),
    &:nth-child(2) {
      position: sticky !important;
      position: -webkit-sticky !important;
      z-index: 20;

      button::before,
      div::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //border-top: 1px solid $border-color;
        //border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }
    }

    &:nth-child(1) {
      left: 0;
      font-size: large;
    }

    &:nth-child(2) {
      left: 12rem;
    }

    &:last-child {
      border-right: 1px solid $border-color;
    }
  }
}

#table-guide {
  margin-top: 4.5rem;

  #lbl-namber-of-sheets {
    width: 100%;
    text-align: center;
  }
}
.not-find {
  color: red;
}

.require {
  color: red;
  display: inline-block;
}

.serch-delivery-dest-grp-column {
  margin-top: 4.5rem;
}

// #access-group {
//   width: 17rem;
//   height: 2rem;
//   padding: 0px 0.5rem 1.5px;
// }

.v-btn.v-btn--has-bg {
  background: $color-obj-base;

  &.api-btn {
    width: 7rem;
    margin: 0 0 0 auto;
  }
}
// #btn-regist {
//   top: 0;
//   left: 0px;
//   padding: 0px;
//   right: 0px;
// }

.list-single ::v-deep,
.list-multi ::v-deep {
  div.v-input__icon {
    background: #95b3d7;
    border-radius: 0.3rem;
  }
}
</style>
